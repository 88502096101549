import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Globals } from '../../globals';
import { CommonModule } from '@angular/common';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from "@progress/kendo-angular-label";
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, CommonModule, DialogsModule, InputsModule, LabelModule],
  templateUrl: './header.component.html'
})
export class HeaderComponent {

  constructor(protected globals: Globals, private commonService: CommonService, private router: Router, private cookieService: CookieService) { }

  public opened = false;
  impersonateDialog() {
    this.opened = true;
  }
  impersonateCloseDialog() {
    this.opened = false;
  }
  isCurrentRoute(route: string, index: number): boolean {
    return this.globals.currentLink.split('/')[index] === route;
  }

  logout() {
    this.commonService.showLoader();
    this.commonService.logout().then(
      (data: any) => {
        this.commonService.hideLoader();
        this.commonService.showNotification(
          'success',
          'Successfully logged out!'
        );
        if (typeof window !== 'undefined') {
          window.location.href = this.globals.baseLogoutUrl;
        }
      },
      (error) => {
        this.commonService.hideLoader();
        if (typeof window !== 'undefined') {
          window.location.href = this.globals.baseLogoutUrl;
        }
      }
    );
  }

}
